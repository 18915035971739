export default [
    {
      path: '/place/lists',
      name: 'place-lists',
      component: () => import('@/views/property-pins/pin-lists/PropertyPinList.vue'),
      meta: {
        resource: 'Place',
        action: 'read',
      },
    },
    {
      path: '/property-pin/add',
      name: 'pin-create',
      component: () => import('@/views/property-pins/pin-form/PinForm.vue'),
      meta: {
        resource: 'Place',
        action: 'create',
      },
    },
    {
      path: '/property-pin/edit/:id',
      name: 'pin-edit',
      component: () => import('@/views/property-pins/pin-form/PinForm.vue'),
      meta: {
        resource: 'Place',
        action: 'update',
      },
    },
    {
      path: '/property-pin/upload/:id',
      name: 'pin-upload',
      component: () => import('@/views/property-pins/pin-upload/pinUpload.vue'),
      meta: {
        resource: 'Property',
        action: 'update',
      },
    },
  ]
    