export default [
    {
        path: '/files',
        name: 'files-read',
        component: () => import('@/views/files/files-list/FilesList.vue'),
        meta: {
          resource: 'Property',
          action: 'read',
        },
      },
]