export default [
  
    {
      path: '/crm/sendmail',
      name: 'send-mail',
      component: ()=> import('@/views/crm/send-mail/send-mail.vue'),
      meta: {
        resource: 'Crm',
        action: 'sendmail',
      },
    },
    {
      path: '/crm/sendmail/edit/:id',
      name: 'send-mail-form',
      component: ()=> import('@/views/crm/send-mail/send-mail.vue'),
      meta: {
        resource: 'Crm',
        action: 'sendmail',
      },
    },
    {
      path: '/crm/subscriber',
      name: 'subscriber-list',
      component: ()=> import('@/views/crm/subscriber-list/SubscriberList.vue'),
      meta: {
        resource: 'Crm',
        action: 'sendmail',
      },
    },
    
    {
      path: '/crm/template',
      name: 'crm-template',
      component: ()=> import('@/views/crm/template/TemplateList.vue'),
      meta: {
        resource: 'Crm',
        action: 'template',
      },
    },
    {
      path: '/crm/template/create',
      name: 'crm-template-create',
      component: ()=> import('@/views/crm/template/TemplateForm.vue'),
      meta: {
        resource: 'Crm',
        action: 'template',
      },
    },
    {
      path: '/crm/template/edit/:id',
      name: 'crm-template-edit',
      component: ()=> import('@/views/crm/template/TemplateForm.vue'),
      meta: {
        resource: 'Crm',
        action: 'template',
      },
    },
    {
      path: '/crm/campaign',
      name: 'crm-campaign',
      component: ()=> import('@/views/crm/campaign/CampaignList.vue'),
      meta: {
        resource: 'Crm',
        action: 'campaign',
      },
    },
    {
      path: '/crm/campaign/users/:id',
      name: 'crm-campaign-users',
      component: ()=> import('@/views/crm/campaign/CampaignUserList.vue'),
      meta: {
        resource: 'Crm',
        action: 'campaign',
      },
    },
    {
      path: '/crm/campaign/create',
      name: 'crm-campaign-create',
      component: ()=> import('@/views/crm/campaign/CampaignForm.vue'),
      meta: {
        resource: 'Crm',
        action: 'campaign',
      },
    },
    {
      path: '/qrcode',
      name: 'qrcode',
      component: ()=> import('@/views/qrcodes/qrCode.vue'),
      meta: {
        resource: 'Crm',
        action: 'campaign',
      },
    },
    
    
 
  ]
  