export default [
    {
      path: '/enquiry/lists',
      name: 'enquiry-lists',
      component: () => import('@/views/enquiry/enquiry-list/EnquiryList.vue'),
      meta: {
        resource: 'Enquiry',
        action: 'read',
      },
    },
    {
      path: '/enquiry/add',
      name: 'enquiry-add',
      component: () => import('@/views/enquiry/enquiry-form/EnquiryForm.vue'),
      meta: {
        resource: 'Enquiry',
        action: 'create',
      },
    },
    {
      path: '/enquiry/client/:clientId',
      name: 'enquiry-client',
      component: () => import('@/views/enquiry/enquiry-form/EnquiryForm.vue'),
      meta: {
        resource: 'Enquiry',
        action: 'create',
      },
    },
    {
      path: '/enquiry/copy/:id',
      name: 'enquiry-copy',
      component: () => import('@/views/enquiry/enquiry-form/EnquiryForm.vue'),
      meta: {
        resource: 'Enquiry',
        action: 'create',
      },
    },
    {
      path: '/enquiry/checkout/:checkout',
      name: 'enquiry-checkout',
      component: () => import('@/views/enquiry/enquiry-form/EnquiryForm.vue'),
      meta: {
        resource: 'Enquiry',
        action: 'create',
      },
    },
    {
      path: '/enquiry/edit/:id',
      name: 'enquiry-edit',
      component: () => import('@/views/enquiry/enquiry-form/EnquiryFormEdit.vue'),
      meta: {
        resource: 'Enquiry',
        action: 'update',
      },
    },
  
    
  ]
    