export default [
   
    {
      path: '/close-deal/add/:id/:property_id',
      name: 'close-deal-create',
      component: () => import('@/views/close-deal/close-deal-form/CloseDealForm.vue'),
      meta: {
        resource: 'Enquiry',
        action: 'create',
      },
    },
    {
      path: '/close-deal/update/:id',
      name: 'close-deal-update',
      component: () => import('@/views/close-deal/close-deal-form/CloseDealEditForm.vue'),
      meta: {
        resource: 'Enquiry',
        action: 'update',
      },
    },
    {
      path: '/close-deal/list',
      name: 'close-deal-lists',
      component: () => import('@/views/close-deal/close-deal-list/CloseDealList.vue'),
      meta: {
        resource: 'CloseDeal',
        action: 'read',
      },
    },
    {
      path: '/close-deal/setting',
      name: 'close-deal-setting',
      component: () => import('@/views/close-deal/close-deal-setting/CloseDealSetting.vue'),
      meta: {
        resource: 'CloseDeal',
        action: 'read',
      },
    },
    
    
  ]
    