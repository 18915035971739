export default [
  {
    path: '/dashboard/analytics',
    name: 'dashboard-analytics',
    component: () => import('@/views/dashboard/analytics/Analytics.vue'),
  },
  {
    path: '/dashboard/ecommerce',
    name: 'dashboard-ecommerce',
    component: () => import('@/views/dashboard/ecommerce/Ecommerce.vue'),
  },
  {
    path: '/dashboard/v-1',
    name: 'dashboard-fbr',
    component: () => import('@/views/dashboard/fbr/dashboard.vue'),
   
    meta: {
      resource: 'Dash',
      action: 'read',
    },
  },
]
