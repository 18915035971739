export default [
    {
      path: '/blogs/lists',
      name: 'blog-lists',
      component: () => import('@/views/blog/blog-list/BlogList.vue'),
      meta: {
        resource: 'Blog',
        action: 'read',
      },
    },
    
    {
        path: '/blogs/add',
        name: 'blog-add',
        component: () => import('@/views/blog/blog-form/BlogForm.vue'),
        meta: {
          resource: 'Blog',
          action: 'create',
        },

      },
      {
        path: '/blogs/edit/:id',
        name: 'blog-edit',
        component: () => import('@/views/blog/blog-form/BlogForm.vue'),
        meta: {
          resource: 'Blog',
          action: 'update',
        },

      },
      {
        path: '/blog-category/lists',
        name: 'blog-category-lists',
        component: () => import('@/views/blog-category/blog-category-list/BlogCategoryList.vue'),
        meta: {
          resource: 'Blog',
          action: 'read',
        },
      },
      {
        path: '/blog-category/add',
        name: 'blog-category-add',
        component: () => import('@/views/blog-category/blog-category-form/BlogCategoryForm.vue'),
        meta: {
          resource: 'Blog',
          action: 'create',
        },

      },
      {
        path: '/blog-category/edit/:id',
        
        name: 'blog-category-edit',
        component: () => import('@/views/blog-category/blog-category-form/BlogCategoryForm.vue'),
        meta: {
          resource: 'Blog',
          action: 'update',
        },

      },

      {
        path: '/knowledgebase-category/lists',
        name: 'knowledgebase-category-lists',
        component: () => import('@/views/knowledgebase-category/knowledgebase-category-list/KnowledgeBaseCategoryList.vue'),
        meta: {
          resource: 'Blog',
          action: 'read',
        },
      },
      {
        path: '/knowledgebase-category/add',
        name: 'knowledgebase-category-add',
        component: () => import('@/views/knowledgebase-category/knowledgebase-category-form/knowledgebaseCategoryForm.vue'),
        meta: {
          resource: 'Blog',
          action: 'create',
        },

      },
      {
        path: '/knowledgebase-category/edit/:id',
        name: 'knowledgebase-category-edit',
        component: () => import('@/views/knowledgebase-category/knowledgebase-category-form/knowledgebaseCategoryForm.vue'),
        meta: {
          resource: 'Blog',
          action: 'create',
        },

      },
      {
        path: '/knowledgebase/lists',
        name: 'knowledgebase-lists',
        component: () => import('@/views/knowledgebase/knowledgebase-list/knowledgebaseList.vue'),
        meta: {
          resource: 'Blog',
          action: 'read',
        },
      },
      {
        path: '/knowledgebase/add',
        name: 'knowledgebase-add',
        component: () => import('@/views/knowledgebase/knowledgebase-form/knowledgebaseForm.vue'),
        meta: {
          resource: 'Blog',
          action: 'read',
        },
      },
      {
        path: '/knowledgebase/edit/:id',
        name: 'knowledgebase-edit',
        component: () => import('@/views/knowledgebase/knowledgebase-form/knowledgebaseForm.vue'),
        meta: {
          resource: 'Blog',
          action: 'read',
        },
      },
      
    
  ]
    