import { $themeConfig } from '@themeConfig'
export default {
    namespaced: true,
    state:{
        filter: {
            searchQuery: localStorage.getItem('p-searchQuery') || '',
            
            unit:localStorage.getItem('p-unit') || '',
            floor:localStorage.getItem('p-floor') || '',
            type_name:localStorage.getItem('p-types') || '',
            building:localStorage.getItem('p-building') || '',
            bedroom:localStorage.getItem('p-bedroom') || '',
            sqm:localStorage.getItem('p-sqm') || '',
            price_sale:localStorage.getItem('p-price_sale') || '',
            propertyfilter:localStorage.getItem('p-propertyfilter') || '',
            ownerfilter:localStorage.getItem('p-ownerfilter') || '',
            statusId:localStorage.getItem('p-statusId') || '',
        }

    },
    getters: {},
    mutations: {
        FILTER_PROPERTY(state,val){
            console.log('change',val);
            localStorage.setItem('p-searchQuery', val.searchQuery)
           
            localStorage.setItem('p-unit', val.unit)
            localStorage.setItem('p-floor', val.floor)
            localStorage.setItem('p-types', val.type_name)
            localStorage.setItem('p-building', val.building)
            localStorage.setItem('p-bedroom', val.bedroom)
            
            localStorage.setItem('p-sqm', val.sqm)
            localStorage.setItem('p-price_sale', val.price_sale)
            localStorage.setItem('p-propertyfilter', val.propertyfilter)
            localStorage.setItem('p-ownerfilter', val.ownerfilter)
            localStorage.setItem('p-statusId', val.statusId)
            

            
            state.filter.searchQuery = val.searchQuery;
            state.filter.unit = val.unit;
            state.filter.floor = val.floor;
            state.filter.type_name = val.type_name;
            state.filter.building = val.building;
            state.filter.bedroom = val.bedroom;
            state.filter.sqm = val.sqm;
            state.filter.price_sale = val.price_sale;
            state.filter.propertyfilter = val.propertyfilter;
            state.filter.ownerfilter = val.ownerfilter;
            state.filter.statusId = val.statusId;

        },
        FILTER_PROPERTY_CLEAR(state){
            localStorage.setItem('p-searchQuery', '')
           
            localStorage.setItem('p-unit', '')
            localStorage.setItem('p-floor', '')
            localStorage.setItem('p-types', '')
            localStorage.setItem('p-building', '')
            localStorage.setItem('p-bedroom', '')
            
            localStorage.setItem('p-sqm', '')
            localStorage.setItem('p-price_sale', '')
            localStorage.setItem('p-propertyfilter', '')
            localStorage.setItem('p-ownerfilter', '')
            localStorage.setItem('p-statusId', '')

          
            state.filter.searchQuery = '';
            state.filter.unit = '';
            state.filter.floor = '';
            state.filter.type_name = '';
            state.filter.building = '';
            state.filter.bedroom = '';
            state.filter.sqm = '';
            state.filter.price_sale = '';
            state.filter.propertyfilter = '';
            state.filter.ownerfilter = '';
            state.filter.statusId = '';
        }
    }
}