export default [
  
    {
      path: '/group-email/list',
      name: 'group-email',
      component: ()=> import('@/views/group-email/group-email-list/GroupEmailList.vue'),
      meta: {
        resource: 'GroupEmail',
        action: 'read',
      },
    },
    {
      path: '/group-email/add',
      name: 'group-email-add',
      component: ()=> import('@/views/group-email/group-email-form/GroupEmailForm.vue'),
      meta: {
        resource: 'GroupEmail',
        action: 'create',
      },
    },
    {
      path: '/group-email/edit/:id',
      name: 'group-email-edit',
      component: ()=> import('@/views/group-email/group-email-form/GroupEmailForm.vue'),
      meta: {
        resource: 'GroupEmail',
        action: 'update',
      },
    },
    
   
  ]
  