import mock from '@/@fake-db/mock'
import jwt from 'jsonwebtoken'

import axios from '@axios'

const data = {
  users: [
    {
      id: 1,
      fullName: 'John Doe',
      username: 'johndoe',
      password: 'admin',
      // eslint-disable-next-line global-require
      avatar: require('@/assets/images/avatars/13-small.png'),
      email: 'admin@demo.com',
      role: 'admin',
      ability: [
        {
          action: 'manage',
          subject: 'all',
        },
      ],
      extras: {
        eCommerceCartItemsCount: 5,
      },
    },
    {
      id: 2,
      fullName: 'Jane Doe',
      username: 'janedoe',
      password: 'client',
      // eslint-disable-next-line global-require
      avatar: require('@/assets/images/avatars/1-small.png'),
      email: 'client@demo.com',
      role: 'client',
      ability: [
        {
          action: 'read',
          subject: 'ACL',
        },
        {
          action: 'read',
          subject: 'Auth',
        },
      ],
      extras: {
        eCommerceCartItemsCount: 5,
      },
    },
  ],
}

// ! These two secrets shall be in .env file and not in any other file
const jwtConfig = {
  secret: 'dd5f3089-40c3-403d-af14-d0c228b05cb4',
  refreshTokenSecret: '7c4c1c50-3230-45bf-9eae-c9b2e401c767',
  expireTime: '10m',
  refreshTokenExpireTime: '10m',
}

mock.onPost('/jwt/login').reply(async request => {
  console.log('request',request);
 // const test = await axios.post('users/v1/login', request.data);
  const res = await axios.post('users/v1/login', request.data);
  console.log('login111', res)
  let user = null;
  if(res.data.success){
    user = res.data.data;
  }
  console.log('user555',user);
  
  //const { email, password } = JSON.parse(request.data)

  let error = {
    email: ['Something went wrong'],
  }
  

  //const user = data.users.find(u => u.email === email && u.password === password)

  if (user) {
  
    if(user.role === 'Super Admin'){
      user.ability = [
        {
          action: 'manage',
          subject: 'all',
        },
      ];
    }else if(user.role === 'Sale Manager'){
      user.ability = [
        {
          action: 'read',
          subject: 'Dash',
        },
        //Property
        {
          action: 'read',
          subject: 'Property',
        },
        {
          action: 'create',
          subject: 'Property',
        },
        {
          action: 'update',
          subject: 'Property',
        },
        {
          action: 'delete',
          subject: 'Property',
        },
        //place
        {
          action: 'read',
          subject: 'Place',
        },
        {
          action: 'create',
          subject: 'Place',
        },
        {
          action: 'update',
          subject: 'Place',
        },
         //enquiry
         {
          action: 'read',
          subject: 'Enquiry',
        },
        {
          action: 'create',
          subject: 'Enquiry',
        },
        {
          action: 'update',
          subject: 'Enquiry',
        },
        {
          action: 'delete',
          subject: 'Enquiry',
        },
        //Blog
        {
          action: 'read',
          subject: 'Blog',
        },
        {
          action: 'create',
          subject: 'Blog',
        },
        {
          action: 'update',
          subject: 'Blog',
        },
        {
          action: 'delete',
          subject: 'Blog',
        },
            //User
      {
        action: 'read',
        subject: 'User',
      },
      {
        action: 'create',
        subject: 'User',
      },
      {
        action: 'update',
        subject: 'User',
      },
      //Group Email
      {
        action: 'read',
        subject: 'GroupEmail',
      },
      {
        action: 'create',
        subject: 'GroupEmail',
      },
      {
        action: 'update',
        subject: 'GroupEmail',
      },
      {
        action: 'read',
        subject: 'CloseDeal',
      },
      {
        action: 'all',
        subject: 'CloseDeal',
      },
      {
        action: 'create',
        subject: 'CloseDeal',
      },
      {
        action: 'update',
        subject: 'CloseDeal',
      },
      {
        action: 'delete',
        subject: 'CloseDeal',
      },


      
      
        
      ];
    }else if(user.role === 'Admin'){
      user.ability = [
        {
          action: 'read',
          subject: 'Dash',
        },
        //Property
        {
          action: 'read',
          subject: 'Property',
        },
        {
          action: 'create',
          subject: 'Property',
        },
        {
          action: 'update',
          subject: 'Property',
        },
        {
          action: 'delete',
          subject: 'Property',
        },
        //place
        {
          action: 'read',
          subject: 'Place',
        },
        {
          action: 'create',
          subject: 'Place',
        },
        {
          action: 'update',
          subject: 'Place',
        },
        {
          action: 'delete',
          subject: 'Place',
        },
         //enquiry
         {
          action: 'read',
          subject: 'Enquiry',
        },
        {
          action: 'create',
          subject: 'Enquiry',
        },
        {
          action: 'update',
          subject: 'Enquiry',
        },
        {
          action: 'delete',
          subject: 'Enquiry',
        },
        //Blog
        {
          action: 'read',
          subject: 'Blog',
        },
        {
          action: 'create',
          subject: 'Blog',
        },
        {
          action: 'update',
          subject: 'Blog',
        },
        {
          action: 'delete',
          subject: 'Blog',
        },
          //User
      {
        action: 'read',
        subject: 'User',
      },
      {
        action: 'create',
        subject: 'User',
      },
      {
        action: 'update',
        subject: 'User',
      },
      //Group Email
      {
        action: 'read',
        subject: 'GroupEmail',
      },
      {
        action: 'create',
        subject: 'GroupEmail',
      },
      {
        action: 'update',
        subject: 'GroupEmail',
      },
      //Inventory
      {
        action: 'read',
        subject: 'Inventory List',
      },
      {
        action: 'create',
        subject: 'Inventory List',
      },
      {
        action: 'update',
        subject: 'Inventory List',
      },
      {
        action: 'read',
        subject: 'RoomType List',
      },
      {
        action: 'create',
        subject: 'RoomType List',
      },
      {
        action: 'update',
        subject: 'RoomType List',
      },
        
      ];
   
  }else if(user.role === 'Sale'){
    user.ability = [
      {
        action: 'read',
        subject: 'Dash',
      },
      //Property
      {
        action: 'read',
        subject: 'Property',
      },
      {
        action: 'create',
        subject: 'Property',
      },
      {
        action: 'update',
        subject: 'Property',
      },
      {
        action: 'delete',
        subject: 'Property',
      },
      //place
      {
        action: 'read',
        subject: 'Place',
      },
      {
        action: 'update',
        subject: 'Place',
      },
       //enquiry
       {
        action: 'read',
        subject: 'Enquiry',
      },
      {
        action: 'create',
        subject: 'Enquiry',
      },
      {
        action: 'update',
        subject: 'Enquiry',
      },
      {
        action: 'delete',
        subject: 'Enquiry',
      },
      //Blog
      {
        action: 'read',
        subject: 'Blog',
      },
      {
        action: 'create',
        subject: 'Blog',
      },
      {
        action: 'update',
        subject: 'Blog',
      },
      //User
      {
        action: 'read',
        subject: 'User',
      },
      {
        action: 'create',
        subject: 'User',
      },
      {
        action: 'update',
        subject: 'User',
      },
      {
        action: 'read',
        subject: 'CloseDeal',
      },
      {
        action: 'mine',
        subject: 'CloseDeal',
      },
     
    
      
    ];

  }
  
    user.extras = {
      eCommerceCartItemsCount: user.eCommerceCartItemsCount.total,
    }
    console.log('login',user);
    try {
      const accessToken = jwt.sign({ id: user.id }, jwtConfig.secret, { expiresIn: jwtConfig.expireTime })
      const refreshToken = jwt.sign({ id: user.id }, jwtConfig.refreshTokenSecret, {
        expiresIn: jwtConfig.refreshTokenExpireTime,
      })

      const userData = { ...user }

      delete userData.password

      const response = {
        userData,
        accessToken,
        refreshToken,
      }
      console.log('res', response)
      return [200, response]
    } catch (e) {
      console.log({e})
      error = e
    }
  } else {
    error = {
      email: ['Email or Password is Invalid'],
    }
  }

  return [400, { error }]
})

mock.onPost('/jwt/register').reply(request => {
  const { username, email, password } = JSON.parse(request.data)

  // If not any of data is missing return 400
  if (!(username && email && password)) return [400]

  const isEmailAlreadyInUse = data.users.find(user => user.email === email)
  const isUsernameAlreadyInUse = data.users.find(user => user.username === username)

  const error = {
    password: !password ? ['Please enter password'] : null,
    email: (() => {
      if (!email) return ['Please enter your email.']
      if (isEmailAlreadyInUse) return ['This email is already in use.']
      return null
    })(),
    username: (() => {
      if (!username) return ['Please enter your username.']
      if (isUsernameAlreadyInUse) return ['This username is already in use.']
      return null
    })(),
  }

  if (!error.username && !error.email) {
    let ability = [
      {
        action: 'manage',
        subject: 'all',
      },

    ];
    const userData = {
      email,
      password,
      username,
      fullName: '',
      avatar: null,
      role: 'admin',
      ability: ability
    }

    // Add user id
    const { length } = data.users
    let lastIndex = 0
    if (length) {
      lastIndex = data.users[length - 1].id
    }
    userData.id = lastIndex + 1

    data.users.push(userData);
    

    const accessToken = jwt.sign({ id: userData.id }, jwtConfig.secret, { expiresIn: jwtConfig.expireTime })
  
    const user = { ...userData }
    delete user.password
    const response = {
      userData: user,
      accessToken,
    }

    return [200, response]
  }
  return [400, { error }]
})

mock.onPost('/jwt/refresh-token').reply(request => {
  console.log('here')
  const { refreshToken } = JSON.parse(request.data)

  try {
    const { id } = jwt.verify(refreshToken, jwtConfig.refreshTokenSecret)

    const userData = { ...data.users.find(user => user.id === id) }

    const newAccessToken = jwt.sign({ id: userData.id }, jwtConfig.secret, { expiresIn: jwtConfig.expiresIn })
    const newRefreshToken = jwt.sign({ id: userData.id }, jwtConfig.refreshTokenSecret, {
      expiresIn: jwtConfig.refreshTokenExpireTime,
    })

    delete userData.password
    const response = {
      userData,
      accessToken: newAccessToken,
      refreshToken: newRefreshToken,
    }

    return [200, response]
  } catch (e) {
    const error = 'Invalid refresh token'
    return [401, { error }]
  }
})
