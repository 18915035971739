export default [
  {
    path: '/property/lists',
    name: 'property-lists',
    component: () => import('@/views/properties/property-list/PropertyLists.vue'),
    meta: {
      resource: 'Property',
      action: 'read',
    },
  },
  {
    path: '/property/add',
    name: 'property-create',
    component: () => import('@/views/properties/property-form/PropertyForm.vue'),
    meta: {
      resource: 'Property',
      action: 'create',
    },
  },
  {
    path: '/property/add/:ownerId',
    name: 'property-owner-create',
    component: () => import('@/views/properties/property-form/PropertyForm.vue'),
    meta: {
      resource: 'Property',
      action: 'create',
    },
  },
  {
    path: '/property/edit/:id',
    name: 'property-edit',
    component: () => import('@/views/properties/property-form/PropertyForm.vue'),
    meta: {
      resource: 'Property',
      action: 'update',
    },
  },
  {
    path: '/property/upload/:id',
    name: 'property-upload',
    component: () => import('@/views/properties/property-upload/propertyUpload.vue'),
    meta: {
      resource: 'Property',
      action: 'update',
    },
  },
  
  
  {
    path: '/property/test',
    name: 'property-test',
    component: () => import('@/views/properties/property-test.vue'),
  },
  
]
  