export default [
    {
      path: '/bts/list',
      name: 'bts-lists',
      component: () => import('@/views/settings/bts/bts-list/BtsList.vue'),
      meta: {
        resource: 'setting',
        action: 'read',
      },
    },
    {
      path: '/bts/add',
      name: 'bts-add',
      component: () => import('@/views/settings/bts/bts-form/BtsForm.vue'),
      meta: {
        resource: 'setting',
        action: 'read',
      },
    },
    {
      path: '/bts/edit/:id',
      name: 'bts-edit',
      component: () => import('@/views/settings/bts/bts-form/BtsForm.vue'),
      meta: {
        resource: 'setting',
        action: 'read',
      },
    },
    {
      path: '/mrt/list',
      name: 'mrt-lists',
      component: () => import('@/views/settings/mrt/mrt-list/MrtList.vue'),
      meta: {
        resource: 'setting',
        action: 'read',
      },
    },
    {
      path: '/mrt/add',
      name: 'mrt-add',
      component: () => import('@/views/settings/mrt/mrt-form/MrtForm.vue'),
      meta: {
        resource: 'setting',
        action: 'read',
      },
    },
    {
      path: '/mrt/edit/:id',
      name: 'mrt-edit',
      component: () => import('@/views/settings/mrt/mrt-form/MrtForm.vue'),
      meta: {
        resource: 'setting',
        action: 'read',
      },
    },
    {
      path: '/airport/list',
      name: 'airport-lists',
      component: () => import('@/views/settings/airport/airport-list/AirportList.vue'),
      meta: {
        resource: 'setting',
        action: 'read',
      },
    },
    {
      path: '/airport/add',
      name: 'airport-add',
      component: () => import('@/views/settings/airport/airport-form/AirportForm.vue'),
      meta: {
        resource: 'setting',
        action: 'read',
      },
    },
    {
      path: '/airport/edit/:id',
      name: 'airport-edit',
      component: () => import('@/views/settings/airport/airport-form/AirportForm.vue'),
      meta: {
        resource: 'setting',
        action: 'read',
      },
    },

    {
      path: '/zone/list',
      name: 'zone-lists',
      component: () => import('@/views/settings/zone/zone-list/ZoneList.vue'),
      meta: {
        resource: 'setting',
        action: 'read',
      },
    },
    {
      path: '/zone/add',
      name: 'zone-add',
      component: () => import('@/views/settings/zone/zone-form/ZoneForm.vue'),
      meta: {
        resource: 'setting',
        action: 'read',
      },
    },
    {
      path: '/zone/edit/:id',
      name: 'zone-edit',
      component: () => import('@/views/settings/zone/zone-form/ZoneForm.vue'),
      meta: {
        resource: 'setting',
        action: 'read',
      },
    },
    {
      path: '/developer/list',
      name: 'developer-lists',
      component: () => import('@/views/settings/developer/developer-list/DeveloperList.vue'),
      meta: {
        resource: 'setting',
        action: 'read',
      },
    },
    {
      path: '/developer/add',
      name: 'developer-add',
      component: () => import('@/views/settings/developer/developer-form/DeveloperForm.vue'),
      meta: {
        resource: 'setting',
        action: 'read',
      },
    },
    {
      path: '/developer/edit/:id',
      name: 'developer-edit',
      component: () => import('@/views/settings/developer/developer-form/DeveloperForm.vue'),
      meta: {
        resource: 'setting',
        action: 'read',
      },
    },
    {
      path: '/amenities/list',
      name: 'amenities-lists',
      component: () => import('@/views/settings/amenities/amenities-list/AmenitiesList.vue'),
      meta: {
        resource: 'setting',
        action: 'read',
      },
    },
    {
      path: '/amenities/add',
      name: 'amenities-add',
      component: () => import('@/views/settings/amenities/amenities-form/AmenitiesForm.vue'),
      meta: {
        resource: 'setting',
        action: 'read',
      },
    },
    {
      path: '/amenities/edit/:id',
      name: 'amenities-edit',
      component: () => import('@/views/settings/amenities/amenities-form/AmenitiesForm.vue'),
      meta: {
        resource: 'setting',
        action: 'read',
      },
    },
    {
      path: '/facilities/list',
      name: 'facilities-lists',
      component: () => import('@/views/settings/facilities/facilities-list/FacilitiesList.vue'),
      meta: {
        resource: 'setting',
        action: 'read',
      },
    },
    {
      path: '/facilities/add',
      name: 'facilities-add',
      component: () => import('@/views/settings/facilities/facilities-form/FacilitiesForm.vue'),
      meta: {
        resource: 'setting',
        action: 'read',
      },
    },
    {
      path: '/facilities/edit/:id',
      name: 'facilities-edit',
      component: () => import('@/views/settings/facilities/facilities-form/FacilitiesForm.vue'),
      meta: {
        resource: 'setting',
        action: 'read',
      },
    },
    
 /*   {
   
        path: '/blogs/add',
        name: 'blog-add',
        component: () => import('@/views/blog/blog-form/BlogForm.vue'),
        meta: {
          resource: 'Blog',
          action: 'create',
        },

      },*/
     
      
    
  ]
    