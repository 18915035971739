export default [
    {
      path: '/phonebook/lists',
      name: 'phonebook-lists',
      component: () => import('@/views/phonebook/phonebook-list/PhoneBookList.vue'),
      meta: {
        resource: 'Phonebook',
        action: 'read',
      },
    },
    {
      path: '/phonebook/create',
      name: 'phonebook-create',
      component: () => import('@/views/phonebook/phonebook-form/PhoneBookForm.vue'),
      meta: {
        resource: 'Phonebook',
        action: 'create',
      },
    },
    {
      path: '/phonebook/service/lists',
      name: 'phonebook-service-lists',
      component: () => import('@/views/phonebook/service-list/ServiceList.vue'),
      meta: {
        resource: 'Phonebook Service',
        action: 'read',
      },
    },
    {
      path: '/phonebook/service/create',
      name: 'phonebook-service-create',
      component: () => import('@/views/phonebook/service-form/ServiceForm.vue'),
      meta: {
        resource: 'Phonebook Service',
        action: 'create',
      },
    },
  ]
    