export default [
  
    {
      path: '/owners/list',
      name: 'owners-list',
      component: ()=> import('@/views/users/owner/owner-list/OwnerList.vue'),
      meta: {
        resource: 'User',
        action: 'read',
      },
    },
    {
      path: '/owners/add',
      name: 'owners-add',
      component: ()=> import('@/views/users/owner/owner-form/OwnersForm.vue'),
      meta: {
        resource: 'User',
        action: 'create',
      },
    },
    {
      path: '/owners/edit/:id',
      name: 'owners-edit',
      component: () => import('@//views/users/owner/owner-form/OwnersForm.vue'),
      meta: {
        resource: 'User',
        action: 'update',
      },
    },
    {
      path: '/clients/list',
      name: 'clients-list',
      component: ()=> import('@/views/users/client/client-list/ClientList.vue'),
      meta: {
        resource: 'User',
        action: 'read',
      },
    },
    {
      path: '/clients/add',
      name: 'clients-add',
      component: ()=> import('@/views/users/client/client-form/ClientsForm.vue'),
      meta: {
        resource: 'User',
        action: 'create',
      },
    },
    {
      path: '/clients/edit/:id',
      name: 'clients-edit',
      component: ()=> import('@/views/users/client/client-form/ClientsForm.vue'),
      meta: {
        resource: 'User',
        action: 'update',
      },
    },
    {
      path: '/agents/list',
      name: 'agents-list',
      component: ()=> import('@/views/users/agent/agent-list/AgentList.vue'),
      meta: {
        resource: 'User',
        action: 'read',
      },
    },
    {
      path: '/agents/add',
      name: 'agents-add',
      component: ()=> import('@/views/users/agent/agent-form/AgentsForm.vue'),
      meta: {
        resource: 'User',
        action: 'create',
      },
    },
    {
      path: '/agents/edit/:id',
      name: 'agents-edit',
      component: ()=> import('@/views/users/agent/agent-form/AgentsForm.vue'),
      meta: {
        resource: 'User',
        action: 'update',
      },
    },
    {
      path: '/agency/list',
      name: 'agency-list',
      component: ()=> import('@/views/users/agency/agency-list/AgencyList.vue'),
      meta: {
        resource: 'agency',
        action: 'read',
      },
    },
    {
      path: '/agency/create',
      name: 'agency-add',
      component: ()=> import('@/views/users/agency/agency-form/AgencyForm.vue'),
      meta: {
        resource: 'agency',
        action: 'create',
      },
    },
    {
      path: '/admin/list',
      name: 'admin-list',
      component: ()=> import('@/views/users/admin/admin-list/AdminList.vue'),
      meta: {
        resource: 'User',
        action: 'read',
      },
    },
    {
      path: '/admin/add',
      name: 'admin-add',
      component: ()=> import('@/views/users/admin/admin-form/AdminsForm.vue'),
      meta: {
        resource: 'User',
        action: 'create',
      },
    },
    {
      path: '/admin/edit/:id',
      name: 'admin-edit',
      component: ()=> import('@/views/users/admin/admin-form/AdminsForm.vue'),
      meta: {
        resource: 'User',
        action: 'update',
      },
    }
   /* {
      path: '/users/list',
      name: 'users-list',
      component: () => import('@/views/user/users-list/UsersList.vue'),
    },
    {
      path: '/users/view/:id',
      name: 'users-view',
      component: () => import('@/views/user/users-view/UsersView.vue'),
    },
    {
      path: '/users/edit/:id',
      name: 'users-edit',
      component: () => import('@/views/user/users-edit/UsersEdit.vue'),
    },
    */
  ]
  