export default [
  
    {
      path: '/matched/property/:code',
      name: 'matched-property',
      component: ()=> import('@/views/matched/property-matched.vue'),
      meta: {
        resource: 'matched',
        action: 'read',
      },
    },
    {
      path: '/matched/enquiry/:enquiry_id',
      name: 'matched-enquiry',
      component: ()=> import('@/views/matched/enquiry-matched.vue'),
      meta: {
        resource: 'matched',
        action: 'read',
      },
    },
  
    
   
  ]
  