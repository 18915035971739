export default [
    {
        path: '/inventory-lists',
        name: 'inventory-lists',
        component: () => import('../../views/inventory-list/inventory-list/InventoryList.vue'),
        meta: {
            resource: 'Inventory List',
            action: 'read',
        },
    },
    {
        path: '/inventory-lists/units',
        name: 'inventory-units',
        component: () => import('../../views/inventory-list/inventory-form/SelectUnitForm.vue'),
        meta: {
            resource: 'Inventory List',
            action: 'read',
        },
    },
    {
        path: '/inventory-lists/add',
        name: 'inventory-add',
        component: () => import('../../views/inventory-list/inventory-form/InventoryForm.vue'),
        meta: {
            resource: 'Inventory List',
            action: 'create',
        },
    },
    {
        path: '/inventory-lists/update',
        name: 'inventory-update',
        component: () => import('../../views/inventory-list/inventory-form/InventoryForm.vue'),
        meta: {
            resource: 'Inventory List',
            action: 'update',
        },
    },
    {
        path: '/inventory-lists/room-type',
        name: 'roomType-list',
        component: () => import('../../views/inventory-list/roomType-list/RoomTypesList.vue'),
        meta: {
            resource: 'RoomType List',
            action: 'read',
        },
    },
    {
        path: '/inventory-lists/room-type/add',
        name: 'roomType-add',
        component: () => import('../../views/inventory-list/roomType-list/RoomTypesForm.vue'),
        meta: {
            resource: 'RoomType List',
            action: 'create',
        },
    },
]