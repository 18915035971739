<template>
  <div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="false"
      margin="5"
      filename="inventory-report.pdf"
      :pdf-quality="2"
      :manual-pagination="false"
      pdf-format="a4"
      pdf-content-width="100%"
      :paginate-elements-by-height="2340"
      @beforeDownload="beforeDownload"
      ref="html2Pdf"
    >
      <div slot="pdf-content" class="pdf-content  text-black p-1">
        <div class="report-header">
          <h2 class="h2-title text-black">Inventory Report</h2>
          <p class="text-font">{{ `${this.data.unit_name} ${this.data.address ? this.data.address : ''} ${this.data.unit ? `(Unit no. ${this.data.unit})` : ''} ${this.data.floor ? `,${this.data.floor}th Floor` : ''} ${this.data.building ? `,Tower ${this.data.building}` : ''}` }}</p>
        </div>
        <div class="details">
          <table style="width: 100%; border-collapse: collapse;">
            <tr>
              <td class="text-font"><strong>Residence:</strong> {{ this.data.unit_name }}</td>
              <td class="text-font"><strong>Date of Record:</strong> {{ this.data.date_record }}</td>
            </tr>
            <tr>
              <td class="text-font"><strong>Unit No:</strong> {{ `${this.data.address? this.data.address: ''} ${this.data.unit? `(Unit ${this.data.unit})`: ''} ${this.data.building? `,Tower ${this.data.building}`: ''}` }}</td>
              <td class="text-font"><strong>Lease Start:</strong> {{ this.data.lease_start }}</td>
            </tr>
            <tr>
              <td class="text-font"><strong>Floor:</strong> {{ this.data.floor }}</td>
              <td class="text-font"><strong>Lease Expire:</strong> {{ this.data.lease_expire }}</td>
            </tr>
          </table>
        </div>
        <div class="table-container" v-for="(item, index) in this.queryData" :key="index + Math.random()">
          <div v-if="item.title == 'KEY HAND OVER'">
            <p class="section-title text-font">{{ item.title }}</p>
            <table class="table-pdf">
              <thead>
                <tr>
                  <th class="th-pdf text-font width-keyhand">Item</th>
                  <th class="th-pdf text-font width-photo-handover">Photo</th>
                  <th class="th-pdf text-font width-quantity">Quantity</th>
                  <th class="th-pdf text-font width-description">Description</th>
                  <th class="th-pdf text-font width-arrival-keyhand">Arrival</th>
                  <th class="th-pdf text-font width-departure-keyhand">Departure</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(furniture, index) in item.furnitures" :key="index">
                  <td class="td-pdf text-font">{{ furniture.title.join(", ") }}</td>
                  <td v-if="index === 0" :rowspan="item.furnitures.length" class="td-pdf">
                    <div class="photo-container">
                      <img :src="imageKeyHand" alt="Furniture Photo" class="img-handover" />
                    </div>
                  </td>
                  <td class="td-pdf text-font">{{ item.furnitures[index].quantity }}</td>
                  <td class="td-pdf text-font">{{ item.furnitures[index].description }}</td>
                  <td class="td-pdf text-font"></td>
                  <td class="td-pdf text-font"></td>
                </tr>
                </tbody>
            </table>
          </div>
        </div>
        <div class="table-container">
          <p class="section-title text-font">Electricity Meter</p>
          <table>
            <thead>
              <tr>
                <th class="th-pdf text-font width-meter">Item</th>
                <th class="th-pdf text-font">Photo</th>
                <th class="th-pdf text-font width-description-meter">Description</th>
                <th class="th-pdf text-font width-arrival">Arrival</th>
                <th class="th-pdf text-font width-departure">Departure</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="td-pdf text-font">Reading record (on arrival)</td>
                <td class="td-pdf">
                  <img :src="electricity_arrival_image" alt="Furniture Photo" class="photo-meter"/>
                </td>
                <td class="td-pdf text-font">{{ this.data.electricity_arrival }}</td>
                <td class="td-pdf text-font"></td>
                <td class="td-pdf text-font"></td>
              </tr>
              <tr>
                <td class="td-pdf text-font">Reading record (on departure)</td>
                <td class="td-pdf">
                  <img :src="electricity_departure_image" alt="Furniture Photo" class="photo-meter"/>
                </td>
                <td class="td-pdf text-font">{{ this.data.electricity_departure }}</td>
                <td class="td-pdf text-font"></td>
                <td class="td-pdf text-font"></td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="table-container">
          <p class="section-title text-font">Water Meter</p>
          <table>
            <thead>
              <tr>
                <th class="th-pdf text-font width-meter">Item</th>
                <th class="th-pdf text-font ">Photo</th>
                <th class="th-pdf text-font width-description-meter">Description</th>
                <th class="th-pdf text-font width-arrival">Arrival</th>
                <th class="th-pdf text-font width-departure">Departure</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td class="td-pdf text-font">Reading record (on arrival)</td>
                <td class="td-pdf">
                  <img :src="water_arrival_image" alt="Furniture Photo" class="photo-meter"/>
                </td>
                <td class="td-pdf text-font">{{ this.data.water_arrival }}</td>
                <td class="td-pdf text-font"></td>
                <td class="td-pdf text-font"></td>
              </tr>
              <tr>
                <td class="td-pdf text-font">Reading record (on departure)</td>
                <td class="td-pdf">
                  <img :src="water_departure_image" alt="Furniture Photo" class="photo-meter"/>
                </td>
                <td class="td-pdf text-font">{{ this.data.water_departure }}</td>
                <td class="td-pdf text-font"></td>
                <td class="td-pdf text-font"></td>
              </tr>
            </tbody>
          </table>
          <p class="note text-font"><strong>Note:</strong> To pay on his/her own bills for the electricity and water supply bills.</p>
        </div>
        <div class="page-break"></div>
        <div class="table-container" v-for="(item, index) in this.data.data" :key="index">
          <div v-if="item.title != 'KEY HAND OVER'">
            <p class="section-title-item text-font">
              <strong>Room Name/Area:</strong> {{ item.title }}
            </p>
            <table class="table-pdf" v-if="item.furnitures.length">
              <thead>
                <tr>
                  <th class="th-pdf text-font width-item">Item</th>
                  <th class="th-pdf text-font width-photo">Photo</th>
                  <th class="th-pdf text-font width-quantity">Quantity</th>
                  <th class="th-pdf text-font width-description">Description</th>
                  <th class="th-pdf text-font width-arrival">Arrival</th>
                  <th class="th-pdf text-font width-departure">Departure</th>
                </tr>
              </thead>
              <tbody v-for="(furniture, index) in item.furnitures" :key="index">
                <tr>
                  <td class="td-pdf text-font">
                    <div v-if="furniture">
                      <span v-html="furniture.title.join(', <br>')"></span>
                    </div>
                  </td>
                  <td class="td-pdf">
                    <div v-for="(image, index) in item.furnitures[index].images" :key="index" class="image-container">
                      <img v-if="image" :src="getSrcFromLists(image)" alt="Furniture Photo" class="photo-img" />
                    </div>
                  </td>
                  <td class="td-pdf text-font">{{ item.furnitures[index].quantity }}</td>
                  <td class="td-pdf text-font">{{ item.furnitures[index].description }}</td>
                  <td class="td-pdf text-font"></td>
                  <td class="td-pdf text-font"></td>
                </tr>
                </tbody>
            </table>

            <div v-if="item.defects.length">
              <p class="section-title-item text-font">
                <strong>Defect Report </strong>
              </p>
              <table class="table-pdf">
                <thead>
                  <tr>
                    <th class="th-pdf text-font width-item">Item</th>
                    <th class="th-pdf text-font width-photo">Photo</th>
                    <th class="th-pdf text-font width-quantity">Quantity</th>
                    <th class="th-pdf text-font width-description">Description</th>
                    <th class="th-pdf text-font width-arrival">Arrival</th>
                    <th class="th-pdf text-font width-departure">Departure</th>
                  </tr>
                </thead>
                <tbody v-for="(defect, index) in item.defects" :key="index">
                  <tr>
                    <td class="td-pdf text-font">
                      <div v-if="defect">
                        <span v-html="defect.title.join(', <br>')"></span>
                      </div>
                    </td>
                    <td class="td-pdf">
                      <div v-for="(image, index) in item.defects[index].images" :key="index" class="image-container">
                        <img v-if="image" :src="getSrcDefectsFromLists(image)" alt="Furniture Photo" class="photo-img" />
                      </div>
                    </td>
                    <td class="td-pdf text-font">{{ item.furnitures[index].quantity }}</td>
                    <td class="td-pdf text-font">{{ item.furnitures[index].description }}</td>
                    <td class="td-pdf text-font"></td>
                    <td class="td-pdf text-font"></td>
                  </tr>
                  </tbody>
              </table>
            </div>
          </div>
          <!-- <div class="page-break"></div> -->
        </div>
        <div class="section-text">
          <p class="text-font">
            This inventory form is for your protection. It helps establish the condition of your
            apartment at the time of your arrival, and should be completed and returned to your
            landlord within the first three days of occupancy. Be specific and check carefully when
            completing this form.
          </p>
          <p class="text-font">
            Look for dust, grease, stains, burns, damages, and wear and tear. Cross out items that do
            not apply and additional paper if more space is needed.
          </p>
          <p class="text-font"><strong>NOTE:</strong> Copies of the entire inventory, including any attachments, should be provided to lessor and lessee.</p>
        </div>
        <div class="signature-section">
          <div class="signature-box text-font">
            LESSOR<br />
            [{{ this.data.lessor }}]
          </div>
          <div class="signature-box text-font">
            LESSEE<br />
            [{{ this.data.lessee }}]
          </div>
        </div>
      </div>
    </vue-html2pdf>
  </div>
</template>

<script>
import VueHtml2pdf from 'vue-html2pdf';
import router from '@/router'
import store from '@/store';
import { ref, onUnmounted } from '@vue/composition-api';
import { base64StringToBlob } from 'blob-util';
import axios from '@axios'

export default {
  components: {
    VueHtml2pdf,
  },
  props: {
  },
  data() {
    return {
      queryData : [],
      data : {},
      imageLists : [],
      imageDefectLists : [],
      imageKeyHand: '',
      src: '',
      electricity_arrival_image: null,
      electricity_departure_image: null,
      water_arrival_image: null,
      water_departure_image: null,
      inventoryPrintData: {}
    };
  },
  methods: {
    getSrcFromLists (url) {
      return this.imageLists.find(v => v.url === url)?.src ?? ''
    },
    getSrcDefectsFromLists (url) {
      return this.imageDefectLists.find(v => v.url === url)?.src ?? ''
    },
    async beforeDownload({ html2pdf, options, pdfContent }) {
      try {
        options = options || {};

        if (!Array.isArray(options.margin)) {
          options.margin = [0.7, 0.1, 0.7, 0.1];
        }

        await html2pdf()
          .set(options)
          .from(pdfContent)
          .toPdf()
          .get('pdf')
          .then((pdf) => {
            pdf.setFontSize(14);
            pdf.setTextColor('#000000');
            pdf.text('test1', 10, 10);
            pdf.text('test2', 10, 20);
            pdf.setFontSize(10);
            pdf.setTextColor(150);
            pdf.text('test3', 10, 30);
          })
          .output('bloburl')
          .then((r) => {
            window.open(r);
          });
      } catch (error) {
        console.error('Error in beforeDownload:', error);
      }
    },
    async toBase64(url) {
      return new Promise((resolve, reject) => {
        const proxyUrl = 'https://cors-anywhere.herokuapp.com/';
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          const dataURL = canvas.toDataURL('image/png');
          resolve(dataURL);
        };
        img.onerror = (err) => reject(err);
        img.src = proxyUrl + url;
      });
    },
    async loadImages(file) {
        const fileName = file.replace("https://digitalnation.sgp1.digitaloceanspaces.com/findbangkokroom/inventory-list/", "");

        const data = {
          filename: fileName,
          folder: 'inventory-list',
        };
        
        let res = await axios.post('/copy/v1/download-s3', data);
        const base64String = `data:${res.data.ContentType};base64,${res.data.base64String}`;

        return 'blob:http://localhost:8282/189b2770-192b-4c5b-8ca7-deabdf0eb0f3';
    },
    async generatePdf() {
      this.$refs.html2Pdf.generatePdf();

      setTimeout(() => {
        window.close();
      }, 1000)
    },
    async getImageSrc(imageUrl) {
        if(imageUrl == null) return ''
        const fileName = imageUrl.replace("https://digitalnation.sgp1.digitaloceanspaces.com/findbangkokroom/inventory-list/", "");

        const data = {
          filename: fileName,
          folder: 'inventory-list',
        };
        
        let res = await axios.post('/copy/v1/download-s3', data);
        const base64String = `data:${res.data.ContentType};base64,${res.data.base64String}`;
        return base64String;
    },
     jsonParseSafe(data) {
      try {
        return JSON.parse(data)
      } catch (err) {
        return null
      }
    },
  },
  async mounted() {

    const { data } = await axios.post(`/property/v1/listsprint/get/${router.currentRoute.query.inventory_id}`)

    const queryData = data

    if (queryData) {
      this.queryData = queryData?.data?.data ?? []
      this.data = queryData.data ?? {}
    }

    for(const item of this.queryData) {
      if(!Array.isArray(item.furnitures)) continue
      for(const furniture of item.furnitures) {
        const images = furniture.images  ?? []

        for(const image of images) {
          const src = await this.getImageSrc(image)
          this.imageLists.push({
            url : image? image: '',
            src
          })
        }
      }

      if(!Array.isArray(item.defects)) continue
      for(const defect of item.defects) {
        const images = defect.images  ?? []

        for(const image of images) {
          const src = await this.getImageSrc(image)
          this.imageDefectLists.push({
            url : image? image: '',
            src
          })
        }
      }
    }

    this.electricity_arrival_image = this.data?.electricity_arrival_image != ''? await this.getImageSrc(this.data?.electricity_arrival_image): ''
    this.electricity_departure_image = this.data?.electricity_departure_image != ''? await this.getImageSrc(this.data?.electricity_departure_image): ''
    this.water_arrival_image = this.data?.water_arrival_image != ''?await this.getImageSrc(this.data?.water_arrival_image): ''
    this.water_departure_image = this.data?.water_departure_image != ''? await this.getImageSrc(this.data?.water_departure_image): ''
    this.imageKeyHand = await this.getImageSrc(this.queryData[0]?.image)
    
    this.generatePdf();
  },
}
</script>

<style>
.pdf-container {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
}

.report-header {
  text-align: center;
}
.details table {
  width: 100%;
  border-collapse: collapse;
}

.details td {
  padding: 5px;
}

.details td strong {
  font-weight: bold;
}
.table-pdf {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}
.th-pdf, .td-pdf {
  border: 1px solid #000;
  text-align: center;
  /* padding: 5px; */
}
.th-pdf {
  background-color: #f2f2f2;
}
.section-title {
  margin-top: 10px;
  font-weight: bold;
}

.section-title-item {
  margin-top: 10px;
}

.photo-img {
  width: 100%;
  height: auto;
  padding : 5px;
  object-fit: cover;
}

.photo-img-keyhand {
  width: 130px;
  height: 110px;
  padding : 5px;
  object-fit: cover;
}

.photo-meter {
  width: 200px;
  height: 88px;
  padding : 5px;
  object-fit: cover;
}

.note {
  font-weight: bold;
  margin-bottom: 15px;
}

.signature-section {
  display: flex;
  justify-content: space-between;
  margin-top: 40px;
}
.signature-box {
  width: 45%;
  border-top: 1px solid #000;
  text-align: center;
  padding-top: 10px;
}
body {
  font-family: Arial, sans-serif;
  margin: 20px;
  line-height: 1.6;
}
.h2-title {
  text-align: center;
}
.section-text {
  margin-top: 20px;
  text-decoration: underline;
}
@media print {
  .page-break {
    page-break-before: always;
  }
  table { 
    page-break-inside: auto;
  }
  tr {
    page-break-inside: avoid;
    page-break-after: auto;
  }
  img {
    page-break-inside: avoid;
  }
}

.page-break {
  page-break-before: always;
  break-before: page;
  height: 0;
}
.width-item{
  width: 16%;
  text-align: center;
}
.width-photo{
  width: 25%;
  text-align: center;
}
.width-quantity{
  width: 5%;
  text-align: center;
}
.width-arrival{
  width: 10%;
  text-align: center;
}
.width-arrival-keyhand{
  width: 9%;
  text-align: center;
}
.width-departure{
  width: 10%;
  text-align: center;
}
.width-departure-keyhand{
  width: 9%;
  text-align: center;
}
.width-keyhand{
  width: 18%;
  text-align: center;
}
.width-meter{
  width: 21%;
  text-align: center;
}
.width-description{
  width: 20%;
  text-align: center;
}
.width-photo-handover{
  width: 25%;
  text-align: center;
}
.width-description-meter{
  width: 31%;
  text-align: center;
}
button {
  margin-right: 10px;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}
textarea {
  font-family: monospace;
}
.pdf-footer {
  text-align: center;
  font-size: 12px;
  color: #333;
  border-top: 1px solid #ccc;
  padding-top: 5px;
}
.height-meter{
  height: 110px;
}
.img-handover{
  width: 180px;
  height: 110px;
  padding : 5px;
  object-fit: cover;
}
.text-font{
  font-size: 11px;
}
.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  page-break-inside: avoid;
}
</style>
